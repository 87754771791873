@import "../../assets/styles/variables";
@import "../../assets/styles/resets";
@import "../../assets//styles/fonts";

.gallery_heading {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  // padding-bottom: 50px;
  color: $primary;
  font-size: 3.5rem;
  // width: 350px;
  text-align: center;
}

hr {
  width: 65px;
  height: 4px;
  background-color: $secondary;
  border: none;
  margin: auto;
  margin-bottom: 80px;
}

@media screen and (max-width: 800px) {

  .gallery_heading {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 2.3rem;
    width: 90%;
    margin: 0 auto;
  }

}